import { useEffect, useState, useRef } from 'react';
import { encodeToBase64 } from 'modules/defines/encrypt';
import cloneDeep from 'lodash/cloneDeep';
import {
  BottomSheetContainer,
  BottomSheetContentsWrapper,
  BottomSheetPurchaseButton,
  BottomSheetBagButton,
  BottomSheetWrapper,
  BottomSheetWrapperTitle,
  BottomSheetButtonRow,
  FinalPriceContainer,
  FinalPriceDesc,
  FinalPriceText,
  DropdownContainer,
  DropdownButton,
  DropdownContent,
  DropdownItem,
  ImportantText,
  SelectedOptionContainer,
  OptionRow,
  OptionTitle,
  QuantityControl,
  PriceText,
  OptionTitleRow,
  OptionNumberRow,
  DiscountText,
  CloseButton,
  FinalPriceWrapper,
} from './BottomSheetCommon';
import { deleteCommaToStr } from 'modules/functions/utils';
import { icon_close_gray } from 'assets/icon';
import { getOptionFinalPrice, printCleanDiscount } from 'utils/util';
import { Modal, AlertModal } from 'components/common/Modal';
import styled from 'styled-components';
import { palette } from 'modules/defines/styles';
import Icon from 'assets/icon/Icon';
import { UserAgent } from 'utils/userAgent';
import { productAPI } from 'api';
import { spinner } from 'assets/lottie';
import { Player } from '@lottiefiles/react-lottie-player';

const BottomSheetDefault = ({
  callback,
  closeBottomSheet,
  product,
  totalPrice,
  uidFromApp,
}) => {
  const { os, browser, device } = UserAgent;

  /* --------------------------------------- 모달 --------------------------------------- */
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);
  const [isSoldOutModalOpen, setIsSoldOutModalOpen] = useState(false);
  const [isNotAppModalOpen, setIsNotAppModalOpen] = useState(false); // 앱이 아닐 때 앱으로 유도 모달
  /* ----------------------------------------------------------------------------------- */

  const [options, setOptions] = useState([]);
  const [optionsSelected, setOptionsSelected] = useState([]);
  const [selectedNum, setSelectedNum] = useState(0); // for detect event
  const [finalPrice, setFinalPrice] = useState(0); // final price (결제 금액)
  const [discountedPrice, setDiscountedPrice] = useState(0);

  const [isGotoAppLoading, setIsGotoAppLoading] = useState(false);

  const [selectedCount, setSeletedCount] = useState(1); // Option이 없을 때 선택한 상품 수량
  const purchaseButtonRef = useRef(null);
  // set 'ESC' keystroke to escape ========================
  const handleKeyDown = (e) => {
    if (e.code === 'Escape') {
      closeBottomSheet();
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  // Initialize (Option 불러와서 적용하기) (Option이 있을 때만)
  useEffect(() => {
    if (product.options !== null && product.options.length !== 0) {
      initializeOptions();
    }
  }, []);

  const initializeOptions = () => {
    const tempData = cloneDeep(product.options);
    setOptions(product.options);
    tempData.map((item) => {
      item['count'] = 0;
    });
    setOptionsSelected(tempData);
  };
  // Option 선택 변경 시 final price 변경 (Option이 있을 때만)
  useEffect(() => {
    var countPrice = 0; // 할인 전 금액
    optionsSelected.map((optionItem) => {
      countPrice += getOptionFinalPrice(
        product.discount,
        product.price,
        optionItem['price'],
        optionItem['count']
      );
    });
    const final_price = countPrice;

    setFinalPrice(final_price);
    setDiscountedPrice(countPrice - final_price);
  }, [optionsSelected]);

  // Check if complete
  useEffect(() => {
    // count
    var totalCount = 0;
    var selectedCount = 0;
    if (totalCount === selectedCount && totalCount !== 0) {
      var isDuplicate = false;
      optionsSelected.map((optionItem) => {
        var buffItem = cloneDeep(optionItem);
        delete buffItem['count'];
        delete buffItem['price'];
        if (JSON.stringify(buffItem) === JSON.stringify(options)) {
          isDuplicate = true;
          optionItem['count'] += 1;
          setOptionsSelected((prev) => [...prev]);
        }
      });
      if (!isDuplicate) {
        var optionsBuff = cloneDeep(options);
        var itemPrice = deleteCommaToStr(totalPrice);
        optionsBuff['price'] = itemPrice;
        optionsBuff['count'] = 1;

        setOptionsSelected((prev) => [...prev, optionsBuff]);
      }

      initializeOptions();
      // Select > Option 초기화
      const optionElements = document.getElementsByClassName('optionClass');
      const elements = Array.from(optionElements);
      elements.forEach((element) => {
        element.selected = true;
      });
    }
  }, [selectedNum]);

  const handleGotoApp = async () => {
    setIsGotoAppLoading(true);
    await productAPI
      .getShareLink({ id: product.productId })
      .then((res) => {
        if (res.data.success) {
          window.location.href = res.data.share_link;
        }
      })
      .finally(() => {
        setIsGotoAppLoading(false);
      });
  };

  // 구매하기 버튼 ( SUBMIT )
  const onClickPurchaseFinalButton = async (e) => {
    e.preventDefault();
    callback();
    var countSelectedOptionTotal = 0; // countByOptions X 일때 예외처리
    var overSelectedOption = false; // countByOptions O 일때 예외처리
    await Promise.all(
      optionsSelected.map((selectedOptionItem) => {
        countSelectedOptionTotal += selectedOptionItem.count;
        if (selectedOptionItem.count > 0) {
          product.options.filter(
            (productOption) => productOption.title === selectedOptionItem.title
          )[0].count < selectedOptionItem.count
            ? (overSelectedOption = true)
            : (overSelectedOption = false);
        }
      })
    );
    // FIXMEFIXMEFIXME
    // if (!browser.isFavApp) {
    //   // setIsNotAppModalOpen(true);
    //   handleGotoApp();
    // } else if (
    if (
      // <  옵션 X  >
      (product.options === null || product.options.length === 0) &&
      selectedCount > product.count
    ) {
      setIsModalOpen(true);
    } else if (
      product.options !== null &&
      product.options.length !== 0 &&
      product.countByOptions === false &&
      countSelectedOptionTotal > product.count
    ) {
      setIsModalOpen(true);
    } else if (
      product.options !== null &&
      product.options.length !== 0 &&
      product.countByOptions === true &&
      overSelectedOption
    ) {
      setIsModalOpen(true);
    } else {
      product.contents = null; // html encoding error 임시방편
      product.managerProducts = null;
      product.otherProducts = null;
      const encodedProduct = encodeToBase64(JSON.stringify(product));
      const encodedOptions = encodeToBase64(JSON.stringify(optionsSelected));
      if (uidFromApp === 0) {
        window.location.href = `/purchase?p=${encodedProduct}&opt=${encodedOptions}&cnt=${selectedCount}`;
      } else {
        const encodedUid = uidFromApp;
        window.location.href = `/purchase?p=${encodedProduct}&opt=${encodedOptions}&cnt=${selectedCount}&uid=${encodedUid}`;
      }
    }
  };

  const onClickCloseButton = (e) => {
    e.preventDefault();
    closeBottomSheet();
  };

  const selectOption = (optionItem) => {
    setOptionsSelected((prevOptions) =>
      prevOptions.map((option) =>
        option.title === optionItem.title
          ? { ...option, count: option.count + 1 }
          : option
      )
    );
    setShow(false);
  };

  const increaseQuantity = (optionItem) => {
    setOptionsSelected((prevOptions) =>
      prevOptions.map((option) =>
        option.title === optionItem.title
          ? { ...option, count: option.count + 1 }
          : option
      )
    );
  };

  const decreaseQuantity = (optionItem) => {
    setOptionsSelected((prevOptions) =>
      prevOptions.map((option) =>
        option.title === optionItem.title && option.count > 0
          ? { ...option, count: option.count - 1 }
          : option
      )
    );
  };

  const removeOption = (optionItem) => {
    setOptionsSelected((prevOptions) =>
      prevOptions.map((option) =>
        option.title === optionItem.title ? { ...option, count: 0 } : option
      )
    );
  };

  const [show, setShow] = useState(false);
  const toggleDropdown = () => {
    setShow(!show);
  };

  return (
    <BottomSheetContainer onClick={(e) => onClickCloseButton(e)}>
      <BottomSheetWrapper onClick={(e) => e.stopPropagation()}>
        <BottomSheetContentsWrapper>
          <BottomSheetWrapperTitle>상품 구매</BottomSheetWrapperTitle>
          {isModalOpen && (
            <AlertModal
              isPadding={false}
              onCancel={() => setIsModalOpen(false)}
              title={'구매하시려는 개수가\n잔여 재고 수보다 많습니다'}
            >
              현재 남은 재고 <Accent>{product.count}개</Accent>
            </AlertModal>
          )}
          {isNotAppModalOpen && (
            <Modal
              isPadding
              size="2:8"
              title="더 많은 혜택을 만나보세요!"
              onConfirm={() => {
                handleGotoApp();
              }}
              onCancel={() => {
                setIsNotAppModalOpen(false);
              }}
              confirmText="앱으로 이동"
              cancelText="취소"
              isLoading={isGotoAppLoading}
            >
              지금 바로 앱으로 이동하여 <br />
              FAV 앱에서만 제공되는 특별한 혜택과 <br />
              할인 상품들을 확인해보세요.
            </Modal>
          )}
          {isCartModalOpen && (
            <AlertModal
              isPadding={false}
              onCancel={() => setIsCartModalOpen(false)}
              title={'알림'}
            >
              장바구니는 현재 준비중입니다
            </AlertModal>
          )}
          {isSoldOutModalOpen && (
            <AlertModal
              isPadding={false}
              onCancel={() => setIsSoldOutModalOpen(false)}
              title={'품절 알림'}
            >
              해당 상품은 품절입니다
            </AlertModal>
          )}

          {/* 옵션이 있는 경우 */}
          {product.options !== null && product.options.length !== 0 && (
            <DropdownContainer>
              <DropdownButton onClick={toggleDropdown}>
                {'옵션'}
                {!product.countByOptions && product.count <= 10 && (
                  <ImportantText>
                    {' '}
                    {'('} 잔여: {product.count}개 {')'}
                  </ImportantText>
                )}
              </DropdownButton>
              <DropdownContent show={show}>
                {options &&
                  options.map((item, idx) => {
                    return (
                      <DropdownItem
                        key={`option-${idx}`}
                        onClick={() =>
                          product.countByOptions
                            ? item.count !== 0
                              ? selectOption(item)
                              : setIsSoldOutModalOpen(true)
                            : product.count !== 0
                              ? selectOption(item)
                              : setIsSoldOutModalOpen(true)
                        }
                      >
                        {item.title} ({Number(item.price) >= 0 && '+'}
                        {item.price.toLocaleString()}원)
                        {product.countByOptions && item.count <= 10 && (
                          <ImportantText> / 잔여: {item.count}개</ImportantText>
                        )}
                      </DropdownItem>
                    );
                  })}
              </DropdownContent>
            </DropdownContainer>
          )}
          {optionsSelected.length !== 0 ? (
            // 옵션 있는 경우
            <SelectedOptionContainer>
              {optionsSelected
                .filter((option) => option.count > 0)
                .map((optionItem, idx) => {
                  return (
                    <OptionRow key={`option-row-${idx}`}>
                      <OptionTitleRow>
                        <OptionTitle>{optionItem.title}</OptionTitle>
                        <CloseButton
                          src={icon_close_gray}
                          onClick={() => removeOption(optionItem)}
                        />
                      </OptionTitleRow>
                      <OptionNumberRow>
                        <QuantityControl>
                          <button onClick={() => decreaseQuantity(optionItem)}>
                            -
                          </button>
                          <span>{optionItem.count}</span>
                          <button onClick={() => increaseQuantity(optionItem)}>
                            +
                          </button>
                        </QuantityControl>
                        <PriceText>
                          {product.discount != 0 && (
                            <DiscountText>
                              {printCleanDiscount(product.discount)}
                              {'%'}{' '}
                            </DiscountText>
                          )}
                          {getOptionFinalPrice(
                            product.discount,
                            product.price,
                            optionItem.price,
                            optionItem.count
                          ).toLocaleString()}
                          원
                        </PriceText>
                      </OptionNumberRow>
                    </OptionRow>
                  );
                })}
            </SelectedOptionContainer>
          ) : (
            // 옵션 없는 경우
            <SelectedOptionContainer>
              <OptionRow>
                <OptionTitleRow>
                  <OptionTitle>
                    {product.title}
                    {product.count <= 10 && (
                      <ImportantText>
                        {' '}
                        {'('} 잔여: {product.count}개 {')'}
                      </ImportantText>
                    )}
                  </OptionTitle>
                </OptionTitleRow>
                <OptionNumberRow>
                  <QuantityControl>
                    <button
                      onClick={() =>
                        setSeletedCount((prev) => (prev <= 1 ? 1 : prev - 1))
                      }
                    >
                      <Icon icon="minus" fill="white" />
                    </button>
                    <span>{selectedCount}</span>
                    <button onClick={() => setSeletedCount((prev) => prev + 1)}>
                      <Icon icon="plus" fill="white" />
                    </button>
                  </QuantityControl>
                  <PriceText>
                    <DiscountText>
                      {printCleanDiscount(product.discount)}
                      {'%'}
                    </DiscountText>
                    &nbsp;
                    {(totalPrice * selectedCount).toLocaleString()}원
                  </PriceText>
                </OptionNumberRow>
              </OptionRow>
            </SelectedOptionContainer>
          )}
          <FinalPriceContainer>
            <FinalPriceDesc>구매 금액</FinalPriceDesc>
            <FinalPriceWrapper>
              {/* {product.discount !== 0 && (
                <FinalDiscountedPrice>
                  {discountedPrice.toLocaleString()}원 할인
                </FinalDiscountedPrice>
              )} */}
              <FinalPriceText>
                {product.options !== null && product.options.length !== 0
                  ? finalPrice.toLocaleString()
                  : (selectedCount * totalPrice).toLocaleString()}
                원
              </FinalPriceText>
            </FinalPriceWrapper>
          </FinalPriceContainer>
        </BottomSheetContentsWrapper>
        <BottomSheetButtonRow>
          {/* <BottomSheetBagButton
            type="button"
            active={
              finalPrice !== 0 ||
              product.options === null ||
              product.options.length === 0
            }
            onClick={() => {
              setIsCartModalOpen(true);
            }}
          >
            장바구니
          </BottomSheetBagButton> */}
          <BottomSheetPurchaseButton
            disabled={
              (finalPrice === 0 &&
                product.options !== null &&
                product.options.length !== 0) ||
              isGotoAppLoading
            }
            isLoading={isGotoAppLoading}
            ref={purchaseButtonRef}
            active={
              finalPrice !== 0 ||
              product.options === null ||
              product.options.length === 0
            }
            onClick={(e) => onClickPurchaseFinalButton(e)}
          >
            {isGotoAppLoading && (
              <LoadingContainer>
                <Player
                  style={{
                    width: '20px',
                    height: '20px',
                  }}
                  id="spinner_player"
                  src={spinner}
                  autoplay
                  loop
                />
              </LoadingContainer>
            )}
            {browser.isFavApp ? '구매하기' : '앱에서 구매하기'}
          </BottomSheetPurchaseButton>
        </BottomSheetButtonRow>
      </BottomSheetWrapper>
    </BottomSheetContainer>
  );
};

const Accent = styled.span`
  color: ${palette.accent};
`;

const LoadingContainer = styled.div`
  margin-right: 0.25rem;
`;
export default BottomSheetDefault;
