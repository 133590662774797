export function makeRandomID(length: number) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function printCleanDiscount(discount: string) {
  let formatted = parseFloat(discount).toFixed(1).toString();

  // '.0'으로 끝나는 경우 제거
  if (formatted.endsWith('.0')) {
    formatted = formatted.slice(0, -2);
  }

  let formatted2 = Math.round(parseFloat(discount));

  return formatted2;
}

export function getOptionFinalPrice(
  discount: string,
  price: string,
  optionPrice: number,
  count: number
) {
  const discounted =
    (1 - parseFloat(discount) / 100) * (parseInt(price) + optionPrice);
  const floor = Math.floor(discounted / 10) * 10;
  return floor * count;
}

export function formatExpireDateStr(expireDate?: string) {
  var returnStr = '';
  if (expireDate) {
    var date = expireDate.split('+')[0].split('T')[0].split('-');
    var time = expireDate.split('+')[0].split('T')[1];
    if (time !== undefined) {
      returnStr =
        date[0] + '년 ' + date[1] + '월 ' + date[2] + '일 ' + time + '까지';
    } else {
      returnStr = date[0] + '년 ' + date[1] + '월 ' + date[2] + '일';
    }
  } else {
    returnStr = '없음';
  }
  return returnStr;
}

export function formatAddress(address: string) {
  // 패턴 정의
  const patterns = [
    // 서울, 인천, 부산 등 패턴
    {
      regex:
        /^(서울|인천|부산|대구|광주|대전|울산|세종|제주)시?\s?(.*?구|.*?군|.*?시)/,
      format: (match: any) => `${match[1]}, ${match[2]}`,
    },
    // 경기도 등 도 패턴 (도 이름을 제거)
    {
      regex:
        /^(경기도|강원도|충청북도|충북|충청남도|충남|전라북도|전북|전라남도|전남|경상북도|경북|경상남도|경남)\s?(.*?시)?\s?(.*?구|.*?군|.*?면)/,
      format: (match: any) => `${match[2] || match[1]}, ${match[3]}`,
    },
  ];

  for (let pattern of patterns) {
    const match = address.match(pattern.regex);
    if (match) {
      return pattern.format(match);
    }
  }

  // 시, 도 패턴에 맞지 않는 경우
  if (address.length > 10) {
    return address.slice(0, 10) + '...';
  }

  return address;
}
