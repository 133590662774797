import styled, { keyframes } from 'styled-components';
import GlobalContainer from './GlobalContainer';
import { useSearchParams } from 'react-router-dom';
import { icon_back, icon_receipt } from 'assets/icon';
import {
  Badge,
  Button,
  HPageTitle20,
  SubTitle,
  TagName,
  TextButton,
  palette,
} from 'modules/defines/styles';
import { userAPI } from 'api';
import { Fragment, useEffect, useState } from 'react';
import {
  ClusteredOption,
  PurchaseItem,
  PurchaseItemBundle,
} from 'modules/defines/interfaces';
import { UserAgent } from 'utils/userAgent';
import { spinner } from 'assets/lottie';
import { Player } from '@lottiefiles/react-lottie-player';
import { Link } from 'react-router-dom';
import { convertToWebp500 } from 'modules/functions/utils';
import { AlertModal } from 'components/common/Modal';
import TabBar from 'components/Purchase/TabBar';
import placeholderImg from 'assets/img/no_img_lg.webp';
import { PageTitle } from 'components/Payment/Layout';
import { formatExpireDateStr } from 'utils/util';
import AppBanner from 'components/common/AppBanner';
import Header from 'components/common/Header';
import { useNavigate } from 'react-router-dom';
import { decodeFromBase64 } from 'modules/defines/encrypt';
import { SafeAreaInAppAndroid } from 'components/common/Common';

interface BottomSheetData {
  expireAt?: string;
  orderId?: string; // db id 가 아닌 order_id (TOSS)
}

const PurchaseListContainer = () => {
  const { os, browser } = UserAgent;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [modal, setModal] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');
  const [purchaseList, setPurchaseList] = useState<PurchaseItem[]>([]);
  const [purchaseBundle, setPurchaseBundle] = useState<PurchaseItemBundle[]>(
    []
  );
  const [activeTab, setActiveTab] = useState<'total' | 'usable' | 'unusable'>(
    'total'
  );

  // const [filteredList, setFilteredList] = useState<PurchaseItem[]>([]);

  // const [showBottomSheet, setShowBottomSheet] = useState<boolean>(false); // 이후 Modal 형태로 컴포넌트화할지는 고민해봐
  const [bsData, setBsData] = useState<BottomSheetData>({}); //
  const [searchParams] = useSearchParams();
  const uidFromApp = searchParams.get('uid'); // 앱에서는 해당 uid로 작동하도록, 웹에서는 null

  const [exceptionMessage, setExceptionMessage] = useState<string>('');
  const navigate = useNavigate();

  // Order Id 같은 아이템끼리 묶기
  const makeOrderBundle = async (purchaseListData: PurchaseItem[]) => {
    var updatedPurchaseBundle: PurchaseItemBundle[] = [];
    await Promise.all(
      purchaseListData.map((item: PurchaseItem, idx: number) => {
        const existingBundle = updatedPurchaseBundle.find(
          (bundle) => bundle.orderId === item.orderId
        );
        if (existingBundle) {
          if (existingBundle.optionInfo === undefined) {
            existingBundle.optionInfo = [];
          }
          existingBundle.optionInfo.push({
            orderNumber: item.orderNumber,
            option_price: item.option_price,
            option: item.option,
            usedAt: item.usedAt,
          });
        } else {
          updatedPurchaseBundle.push({
            ...item,
            optionExist: item.option !== null,
            optionInfo: [
              {
                orderNumber: item.orderNumber,
                option_price: item.option_price,
                option: item.option,
                usedAt: item.usedAt,
              },
            ],
          });
        }
      })
    );

    setPurchaseBundle(updatedPurchaseBundle);
    // setPurchaseBundle([
    //   {
    //     store_product_id: 1,
    //     createdAt: '2021-09-01T00:00:00',
    //     reconciledAt: '2021-09-01T00:00:00',
    //     option: {
    //       title: '옵션 이름',
    //       price: 0,
    //     },
    //     orderId: '1',
    //     customerName: '고객 이름',
    //     customerPhone: '010-0000-0000',

    //     orderIdNumber: '1',
    //     paidAt: '2021-09-01T00:00:00',
    //     spaceTitle: '공간 이름',
    //     orderName: '상품 이름',
    //     productImg: 'https://via.placeholder.com/150',
    //     optionExist: true,
    //     optionInfo: [
    //       {
    //         orderNumber: '1',
    //         option_price: 0,
    //         option: {
    //           title: '옵션 이름',
    //           price: 0,
    //         },
    //         usedAt: '2021-09-01T00:00:00',
    //       },
    //       {
    //         orderNumber: '2',
    //         option_price: 0,
    //         option: {
    //           title: '사과유리다관 - Magpie&Tiger Original',
    //           price: 9000,
    //         },
    //         usedAt: '2021-09-01T00:00:00',
    //       },
    //       {
    //         orderNumber: '1',
    //         option_price: 0,
    //         option: {
    //           title: '옵션 이름22',
    //           price: 0,
    //         },
    //         usedAt: '2021-09-01T00:00:00',
    //       },
    //       {
    //         orderNumber: '1',
    //         option_price: 0,
    //         option: {
    //           title: '옵션 이름3',
    //           price: 0,
    //         },
    //         usedAt: '2021-09-01T00:00:00',
    //       },
    //       {
    //         orderNumber: '1',
    //         option_price: 0,
    //         option: {
    //           title: '옵션 이름9',
    //           price: 0,
    //         },
    //         usedAt: '2021-09-01T00:00:00',
    //       },
    //       {
    //         orderNumber: '1',
    //         option_price: 0,
    //         option: {
    //           title: '옵션 이름10',
    //           price: 0,
    //         },
    //         usedAt: '2021-09-01T00:00:00',
    //       },
    //     ],
    //     total_price: 0,
    //     usedAt: '2021-09-01T00:00:00',
    //     refundAt: '2021-09-01T00:00:00',
    //     expireAt: '2021-09-01T00:00:00',
    //   },
    //   {
    //     store_product_id: 1,
    //     createdAt: '2021-09-01T00:00:00',
    //     reconciledAt: '2021-09-01T00:00:00',
    //     option: {
    //       title: '옵션 이름',
    //       price: 0,
    //     },
    //     orderId: '1',
    //     customerName: '고객 이름',
    //     customerPhone: '010-0000-0000',

    //     orderIdNumber: '1',
    //     paidAt: '2021-09-01T00:00:00',
    //     spaceTitle: '공간 이름',
    //     orderName: '상품 이름',
    //     productImg: 'https://via.placeholder.com/150',
    //     optionExist: true,
    //     optionInfo: [
    //       {
    //         orderNumber: '1',
    //         option_price: 0,
    //         option: {
    //           title: '옵션 이름',
    //           price: 0,
    //         },
    //         usedAt: '2021-09-01T00:00:00',
    //       },
    //     ],
    //     total_price: 0,
    //     usedAt: '2021-09-01T00:00:00',
    //     refundAt: '2021-09-01T00:00:00',
    //     expireAt: '2021-09-01T00:00:00',
    //   },
    //   {
    //     store_product_id: 1,
    //     createdAt: '2021-09-01T00:00:00',
    //     reconciledAt: '2021-09-01T00:00:00',
    //     option: {
    //       title: '옵션 이름',
    //       price: 0,
    //     },
    //     orderId: '1',
    //     customerName: '고객 이름',
    //     customerPhone: '010-0000-0000',

    //     orderIdNumber: '1',
    //     paidAt: '2021-09-01T00:00:00',
    //     spaceTitle: '공간 이름',
    //     orderName: '상품 이름',
    //     productImg: 'https://via.placeholder.com/150',
    //     optionExist: true,
    //     optionInfo: [
    //       {
    //         orderNumber: '1',
    //         option_price: 0,
    //         option: {
    //           title: '옵션 이름',
    //           price: 0,
    //         },
    //         usedAt: '2021-09-01T00:00:00',
    //       },
    //     ],
    //     total_price: 0,
    //     usedAt: '2021-09-01T00:00:00',
    //     refundAt: '2021-09-01T00:00:00',
    //     expireAt: '2021-09-01T00:00:00',
    //   },
    // ]);
  };

  useEffect(() => {
    setIsLoading(true);

    if (uidFromApp) {
      const headers = {
        id: encodeURIComponent(decodeFromBase64(uidFromApp)),
      };

      userAPI
        .purchaseList(headers)
        .then((res) => {
          if (res.data.message === 'OK') {
            console.log('data', res.data);
            makeOrderBundle(res.data.userPurchaseList); // Bundle 만들기
            setPurchaseList(res.data.userPurchaseList);

            if (res.data.userPurchaseList.length === 0)
              setExceptionMessage('결제 내역이 없습니다.');
          } else if (res.data.message === 'USER_NOT_FOUND') {
            setExceptionMessage('유저 정보가 확인되지 않습니다.');
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error, 'error');
          setIsLoading(false);
          setExceptionMessage('유저 정보가 확인되지 않습니다.');
        });
    } else {
      setExceptionMessage('유저 정보가 확인되지 않습니다.');
      setIsLoading(false);
    }
  }, []);

  const productUseHandler = (
    e: any,
    orderIdNumber: string,
    expireAt: string,
    orderId: string
  ) => {
    e.stopPropagation();

    window.location.href = `/purchase-ticket/${orderIdNumber}?uid=${uidFromApp}`;
  };

  const itemClickListener = (e: any, itemId: string) => {
    // 결제 상세 정보로 이동
    e.stopPropagation();
    window.location.href = `/purchase/${itemId}?uid=${uidFromApp}`;
  };

  const filterItem = purchaseBundle.filter((item) => {
    if (activeTab === 'total') return item;
    if (activeTab === 'usable') {
      return (
        item.usedAt === null &&
        item.refundAt === null &&
        (item.expireAt === null ||
          (item.expireAt && item.expireAt > new Date().toISOString() === true))
      );
    }
    if (activeTab === 'unusable')
      return (
        item.usedAt !== null ||
        item.refundAt !== null ||
        (item.expireAt && item.expireAt > new Date().toISOString() === false)
      );
  });

  if (!purchaseList) {
    return (
      <Fragment>
        <LoadingWrapper>
          <Loading width="100%" height={36} />
          <Divider005 />
          <Loading width="100%" height={22} marinBottom="4px" />
          <Loading width="100%" height={22} marinBottom="8px" />
          <PurchaseInfoWrapper>
            <Loading width={'100%'} height={120} />
          </PurchaseInfoWrapper>
          <Divider003 />
          <Loading width="100%" height={22} />
        </LoadingWrapper>
        <LoadingWrapper>
          <Loading width="100%" height={36} />
          <Divider005 />
          <Loading width="100%" height={22} marinBottom="4px" />
          <Loading width="100%" height={22} marinBottom="8px" />
          <PurchaseInfoWrapper>
            <Loading width={'100%'} height={120} />
          </PurchaseInfoWrapper>
          <Divider003 />
          <Loading width="100%" height={22} />
        </LoadingWrapper>
        <LoadingWrapper>
          <Loading width="100%" height={36} />
          <Divider005 />
          <Loading width="100%" height={22} marinBottom="4px" />
          <Loading width="100%" height={22} marinBottom="8px" />
          <PurchaseInfoWrapper>
            <Loading width={'100%'} height={120} />
          </PurchaseInfoWrapper>
          <Divider003 />
          <Loading width="100%" height={22} />
        </LoadingWrapper>
      </Fragment>
    );
  }

  return (
    <GlobalContainer padding={false}>
      {os.isAndroid && browser.isFavApp && <SafeAreaInAppAndroid />}
      <AppBanner os={os} browser={browser} isFixed={false} />
      <Header isFavApp={browser.isFavApp} title={''} />

      {purchaseList.length > 0 && (
        <TabBar
          usableLength={
            purchaseList.filter(
              (item) =>
                item.usedAt === null &&
                item.refundAt === null &&
                (item.expireAt === null ||
                  (item.expireAt &&
                    item.expireAt > new Date().toISOString() === true))
            ).length
          }
          unusableLength={
            purchaseList.filter(
              (item) =>
                item.usedAt !== null ||
                item.refundAt !== null ||
                (item.expireAt &&
                  item.expireAt > new Date().toISOString() === false)
            ).length
          }
          active={activeTab}
          setActive={setActiveTab}
        />
      )}

      <SectionContainer>
        {modal && (
          <AlertModal
            title="알림"
            isPadding={false}
            onCancel={() => setModal(false)}
          >
            {modalMessage}
          </AlertModal>
        )}

        {isLoading ? (
          <>
            <Player
              style={{
                width: '48px',
                height: '48px',
              }}
              id="spinner_player"
              src={spinner}
              autoplay
              loop
            />
          </>
        ) : purchaseList.length === 0 ? (
          <ExceptionWrapper>
            <i>
              <img src={icon_receipt} alt="icon_receipt" />
            </i>
            <div>
              <CommentTitle>{exceptionMessage}</CommentTitle>
              <CommentText>
                {exceptionMessage.includes('유저')
                  ? ''
                  : '마음에 드는 상품을 구매해 주세요'}
              </CommentText>
            </div>
            {/* <ButtonText to="/">
              {exceptionMessage.includes('유저')
                ? '로그인 하러가기'
                : '마음에 드는 상품을 구매해 주세요'}
            </ButtonText> */}
          </ExceptionWrapper>
        ) : (
          filterItem.map((item, idx) => {
            console.log('item', item);
            const itemCount = item.optionInfo.length;
            const paidAt = item.paidAt.split('T')[0].replace(/-/g, '.');
            // option Info clustering
            var clusteredOption: ClusteredOption[] = [];
            item.optionInfo.map((item, idx2) => {
              if (item.option) {
                const found = clusteredOption.find(
                  (cluster: ClusteredOption) =>
                    cluster.title === item.option.title
                );
                if (found) {
                  found.count += 1;
                } else {
                  clusteredOption.push({
                    title: item.option.title,
                    price: item.option.price,
                    count: 1,
                  });
                }
              }
            });
            var optionTotallyUsed: boolean =
              item.optionInfo.find((optInfo) => optInfo.usedAt === null) ===
              undefined;
            console.log('optionTotallyUsed', item.optionInfo);

            return (
              <PurchaseItemWrapper
                disabled={
                  item.refundAt !== null ||
                  item.expireAt > new Date().toISOString() === false
                }
                key={`purchase-item-${idx}`}
                onClick={(e) => {
                  if (
                    // item.usedAt ||
                    item.refundAt !== null ||
                    (item.expireAt &&
                      item.expireAt > new Date().toISOString() === false)
                  ) {
                    // if (item.usedAt) {
                    //   setModalMessage('이미 사용 완료된 상품입니다.');
                    // }
                    if (item.refundAt !== null) {
                      setModalMessage('환불 완료된 상품입니다.');
                    }
                    if (
                      item.expireAt &&
                      item.expireAt > new Date().toISOString() === false
                    ) {
                      setModalMessage('기간 만료된 상품입니다.');
                    }
                    setModal(true);
                  } else {
                    itemClickListener(e, item.orderIdNumber);
                  }
                }}
              >
                {/* 날짜, 주문번호, 사용버튼 */}
                <PurchaseItemHeader>
                  <FlexRow>
                    <PurchaseItemPaidText>{paidAt}</PurchaseItemPaidText>
                    <OrderNumberText>
                      주문번호 {item.orderIdNumber}
                    </OrderNumberText>
                  </FlexRow>
                </PurchaseItemHeader>
                <Divider005 />
                {/* 상품 정보 */}
                <PurchaseInfoContainer>
                  <SpaceTitle
                    disabled={
                      item.refundAt !== null ||
                      item.expireAt > new Date().toISOString() === false
                    }
                  >
                    {item.spaceTitle}
                  </SpaceTitle>
                  <ProductTitle>{item.orderName}</ProductTitle>
                  <OrderExpireText
                    disabled={
                      item.refundAt !== null ||
                      item.expireAt > new Date().toISOString() === false
                    }
                  >{`유효기간 : ${formatExpireDateStr(item.expireAt)}`}</OrderExpireText>
                  <PurchaseInfoWrapper>
                    <ProductImg
                      src={
                        item.productImg
                          ? convertToWebp500(item.productImg)
                          : item.spaceImg
                            ? convertToWebp500(item.spaceImg)
                            : placeholderImg
                      }
                    />
                    <OptionInfoWrapper>
                      {item.optionExist ? (
                        clusteredOption.map(
                          (item: ClusteredOption, idx: number) => {
                            return (
                              <OptionInfoRow key={`option-info-${idx}`}>
                                <OptionInfoText>
                                  <OptionInfoName>{`${item.title}`}</OptionInfoName>
                                  {item.price !== 0 &&
                                    `(${item.price > 0 ? `+${item.price}` : item.price}원)`}
                                </OptionInfoText>
                                <OptionInfoText className="count">
                                  수량 {item.count}
                                  <MiniSpacing>개</MiniSpacing>
                                </OptionInfoText>
                              </OptionInfoRow>
                            );
                          }
                        )
                      ) : (
                        <OptionInfoRow>
                          <OptionInfoText className="product">
                            {item.orderName}
                          </OptionInfoText>
                          <OptionInfoText className="count">
                            수량 {itemCount}개
                          </OptionInfoText>
                        </OptionInfoRow>
                      )}
                    </OptionInfoWrapper>
                  </PurchaseInfoWrapper>
                </PurchaseInfoContainer>
                <Divider003 />

                {/* 수량 및 가격 */}
                <FinalContainer>
                  <CountText>수량 {itemCount}개</CountText>
                  <PriceText>
                    {item.total_price.toLocaleString()}
                    <MiniSpacing>원</MiniSpacing>
                  </PriceText>
                </FinalContainer>
                <ConfirmButton
                  marginTop={1.25}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (
                      optionTotallyUsed ||
                      item.refundAt !== null ||
                      (item.expireAt &&
                        item.expireAt > new Date().toISOString() === false)
                    ) {
                      setModal(true);
                      if (optionTotallyUsed) {
                        setModalMessage('이미 사용 완료된 상품입니다.');
                      }
                    } else {
                      productUseHandler(
                        e,
                        item.orderIdNumber,
                        item.expireAt,
                        item.orderId
                      );
                    }
                  }}
                  disabled={
                    optionTotallyUsed ||
                    item.refundAt !== null ||
                    (item.expireAt !== null &&
                      item.expireAt > new Date().toISOString() === false)
                  }
                >
                  {optionTotallyUsed
                    ? '사용 완료'
                    : item.refundAt !== null
                      ? '환불 완료'
                      : item.expireAt !== null &&
                          item.expireAt > new Date().toISOString() === false
                        ? '기간 만료'
                        : '사용하기'}
                </ConfirmButton>
              </PurchaseItemWrapper>
            );
          })
        )}
      </SectionContainer>
      {/* {showBottomSheet && (
        <BottomSheetUseProduct
          closeBottomSheet={onClickBottomSheetClose}
          expireAt={bsData.expireAt || ''}
          orderId={bsData.orderId || ''}
        />
      )} */}
    </GlobalContainer>
  );
};

const MiniSpacing = styled.span`
  margin-left: 0.12rem;
`;

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  ${PageTitle};
`;

const loading = keyframes`
  0% {
    background-color: rgba(0, 0, 0, 0.04);
  }
  50% {
    background-color: rgba(0, 0, 0, 0.08);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
const Loading = styled.div<{
  width?: number | string;
  height?: number;
  marinBottom?: string;
}>`
  border-radius: 4px;
  animation: ${loading} 1s linear infinite;

  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height}px;
  margin-bottom: ${(props) => props.marinBottom || '0px'};
`;

const ConfirmButton = styled.button<{ marginTop?: number }>`
  ${Button};
  width: 100%;
  color: white;
  background: ${palette.primary};
  padding-top: 0.87rem;
  padding-bottom: 0.88rem;

  border-radius: 0.625rem;
  text-decoration: none;
  cursor: pointer;
  margin-top: ${(props) => props.marginTop || 0}rem;
  &:disabled {
    background: ${palette.neutral001};
    color: rgba(193, 192, 201, 1);
    cursor: default;
  }
`;

const LoadingWrapper = styled.div`
  margin-bottom: 30px;
`;

const CommentTitle = styled.b`
  ${HPageTitle20};
  color: ${palette.neutral007};
`;

const CommentText = styled.div`
  ${TextButton};
  color: ${palette.neutral004};
`;

const SectionContainer = styled.div`
  margin-top: 1.88rem;
  display: flex;
  flex-direction: column;
  padding: 0px 25px;
  gap: 30px;
  height: fit-content;
  position: relative;
  /* min-height: 100vh; */
  padding-bottom: 80px;
`;

const PurchaseItemWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  ${(props) => props.disabled && 'cursor: default;'}
`;
const PurchaseItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const PurchaseInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;
const SpaceTitle = styled.div<{ disabled: boolean }>`
  color: ${palette.neutral007};
  ${SubTitle};
  ${(props) => props.disabled && `color: ${palette.neutral006};`}
`;
const ProductTitle = styled.div`
  color: ${palette.neutral007};
  ${TextButton}
`;
const PurchaseInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.625rem;
  margin: 0.75rem 0;
`;
const ProductImg = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 4px;
  border: 1px solid ${palette.neutral002};
  object-fit: cover;
`;

const Divider005 = styled.div`
  background: ${palette.neutral005};
  height: 1px;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;
const Divider003 = styled.div`
  background: ${palette.neutral003};
  height: 1px;
  width: 100%;
  margin-bottom: 0.94rem;
`;
const PurchaseItemPaidText = styled.div`
  color: ${palette.neutral007};
  ${SubTitle};
  font-weight: 600;
`;

const OptionInfoName = styled.div`
  max-width: 160px;
  ${TextButton};
  margin-right: 0.25rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const OptionInfoText = styled.div`
  color: ${palette.neutral006};
  display: flex;
  align-items: center;

  ${TextButton}
`;

const OrderExpireText = styled.div<{ disabled: boolean }>`
  color: ${palette.accent};
  ${Badge};

  margin-top: 0.13rem;
  font-weight: 500;
  ${(props) => props.disabled && `color: ${palette.neutral006};`}
`;

const OptionInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 0.5rem;
`;

const OptionInfoRow = styled.div`
  display: flex;

  justify-content: space-between;
`;

const FinalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const CountText = styled.div`
  color: ${palette.neutral007};

  ${SubTitle};
`;
const PriceText = styled.div`
  color: ${palette.neutral007};
  ${HPageTitle20};
`;
const FlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  /* gap: 0.375rem; */
  justify-content: space-between;
  align-items: center;
`;
const OrderNumberText = styled.div`
  color: ${palette.neutral005};
  ${Badge};
  vertical-align: middle;
  align-self: center;
  place-self: center;
`;

const ExceptionWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100dvh - 200px);

  overflow: hidden;
  gap: 24px;
`;

export default PurchaseListContainer;
