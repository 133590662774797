import styled, { css, keyframes } from 'styled-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';
import { useEffect, useState } from 'react';
import GlobalContainer from './GlobalContainer';
import { palette } from 'modules/defines/styles';
import { productAPI } from 'api';
import AppBanner from 'components/common/AppBanner';
import { UserAgent } from 'utils/userAgent';
import { MainSpaceCarousel } from 'components/common/Carousel';
import ProductItem from 'components/common/ProductItem';
import StoreBanner from 'components/common/StoreBanner';
import { LoadingProductListSkeleton } from 'components/common/LoadingSkeleton';
import { appOpenLog, viewPageLog } from 'utils/amplitude';
import { SafeAreaInAppAndroid } from 'components/common/Common';
import {
  facebookPixelTrack,
  useFacebookPixelPageView,
} from 'utils/facebookPixel';

const MainContainer = () => {
  const { os, browser, device } = UserAgent;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isAppQuery = searchParams.get('type') || null;
  const isTest = searchParams.get('ver') === 'test'; // 앱에서는 해당 uid로 작동하도록, 웹에서는 null
  const [productList, setProductList] = useState(); // product data
  const [spaceList, setSpaceList] = useState(); // space data
  const [isLoading, setIsLoading] = useState(true);

  useFacebookPixelPageView();
  facebookPixelTrack('ViewPage', { page: 'main' });

  useEffect(() => {
    appOpenLog(isAppQuery);
    viewPageLog('main'); //amplitude tracking
    const headers = {
      istest: isTest,
    };
    productAPI.getListMain(headers).then((items) => {
      setProductList(items?.data?.storeProducts);
      setSpaceList(items?.data?.spaces);
    });
  }, []);

  useEffect(() => {
    // console.log('productList', productList);
    // console.log('spaceList', spaceList);
    if (productList && spaceList) {
      setIsLoading(false);
    }
  }, [productList, spaceList]);

  return (
    <GlobalContainer padding={false}>
      {os.isAndroid && browser.isFavApp && <SafeAreaInAppAndroid />}
      <AppBanner os={os} browser={browser} isFixed={false} />
      <FixedContainer isFavApp={browser.isFavApp}>
        <HeaderContainer isFavApp={browser.isFavApp}>
          <HeaderTitle>Store.</HeaderTitle>
          <HeaderButtonContainer>
            {/* <HeaderButtonWrapper>장바구니</HeaderButtonWrapper> */}
            <HeaderButtonWrapper
              onClick={
                () => (window.location.href = '/purchase-list')
                // navigate('/purchase-list')
              }
            >
              결제내역
            </HeaderButtonWrapper>
          </HeaderButtonContainer>
        </HeaderContainer>
      </FixedContainer>
      <ContentContainer>
        <SpaceListContainer>
          <MainSpaceCarousel spaceInfos={spaceList} isLoading={isLoading} />
        </SpaceListContainer>

        <HeaderWrapper>
          <HeaderTitle>주목해야 할 상품</HeaderTitle>
        </HeaderWrapper>
        {!isLoading ? (
          <ProductListContainer>
            {productList?.map((product, idx) => {
              return (
                <ProductItem
                  key={`${product.id}_${idx}`}
                  productId={product.productId}
                  category={product.category}
                  space_title={product.brand}
                  title={product.title}
                  discount={product.discount}
                  price={product.discounted_price}
                  img={product.img_webp}
                  type={'main'}
                />
              );
            })}
          </ProductListContainer>
        ) : (
          <LoadingProductListSkeleton count={9} />
        )}
        <StoreBanner isFixed={false} marginBottom="0px" marginTop="25px" />
      </ContentContainer>
    </GlobalContainer>
  );
};

////////////////////////////////////////////////////////////////////

const FixedContainer = styled.div`
  position: relative;
  height: 4.375rem; // 70px
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${palette.white};
  ${(props) =>
    props.isFavApp &&
    css`
      margin-bottom: 0.938rem;
    `};
`;
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 4.375rem; // 70px
  // height: 3.125rem; // 50px
  // padding: 0 0.625rem;
  max-width: 40rem;
  width: 100%;

  ${(props) =>
    props.isFavApp &&
    css`
      position: fixed;
      z-index: 1000;
      background-color: ${palette.white};
    `};
`;
const HeaderTitle = styled.div`
  color: ${palette.neutral007};

  /* H_PageTitle-20 */
  font-family: Pretendard;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem; /* 120% */
  margin-left: 1.563rem;
`;

const HeaderWrapper = styled.div`
  margin-top: 3.75rem;
  margin-bottom: 1.563rem;
  padding: 0.25rem 0;
`;
const HeaderButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
`;
const HeaderButtonWrapper = styled.div`
  padding: 6px 10px;
  color: ${palette.neutral007};
  text-align: right;
  font-feature-settings:
    'liga' off,
    'clig' off;

  /* TextButton */
  font-family: Pretendard;
  font-size: 0.938rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
  cursor: pointer;
  margin-right: 0.938rem;

  &:hover {
    color: ${palette.neutral005};
  }
`;
/////////////////////////////////////////////////////////////////////
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 4rem;
  width: 100%;
`;

const SpaceListContainer = styled.div``;

const ProductListContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
  column-gap: 0.125rem;
  row-gap: 1.25rem;
  @media screen and (max-width: 620px) {
    grid-template-columns: repeat(2, minmax(120px, 1fr));
  }
`;

export default MainContainer;
