import styled from 'styled-components';
import GlobalContainer from '../GlobalContainer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { paymentAPI } from 'api';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import {
  BodySubmenu,
  HPageTitle20,
  SubTitle,
  TextButton,
  palette,
} from 'modules/defines/styles';
import { decodeFromBase64 } from 'modules/defines/encrypt';
import { PaymentInfo } from 'modules/defines/interfaces';
import LoadingPage from 'components/common/LoadingPage';
import { CloseButton } from 'components/ProductDetail/BottomSheetCommon';
import { icon_close_black } from 'assets/icon';
import { ButtonSolid60, ButtonTextRegular } from 'components/common/Button';
import { addCommaToNum, calOriginalPrice } from 'modules/functions/utils';
import {
  ButtonContainer,
  ContentsContainer,
  ContentsWrapper,
  HeaderWrapper,
  IconWrapper,
  PageTitle,
} from 'components/Payment/Layout';
import { getOptionFinalPrice, printCleanDiscount } from 'utils/util';
import { AlertModal } from 'components/common/Modal';
import { facebookPixelViewMessage } from 'utils/facebookPixel';
const PaymentSuccess = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true); // true
  const queryParams = new URLSearchParams(location.search);
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfo | null>(null);
  const paymentInfoParams = queryParams.get('p'); // for [Toss Payments]
  const oidParams = queryParams.get('oid'); // for [Hecto Payments]
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const [isOrderNotFound, setIsOrderNotFound] = useState(false);

  // 1 ) for payment (TOSS)
  const paymentSigning = async (paymentItem: PaymentInfo) => {
    try {
      setIsLoading(true);
      if (queryParams.get('amount')) {
        const data = {
          amount: queryParams.get('amount'),
          orderId: queryParams.get('orderId'),
          paymentKey: queryParams.get('paymentKey'),
          authorization: btoa(process.env.REACT_APP_TOSS_SECRET_KEY + ':'),
          // 추가 정보
          customerName: paymentItem.customerName,
          customerPhone: paymentItem.customerPhone,
          discount: paymentItem.discount,
          option: paymentItem.selectedOption,
          user_id: paymentItem.user_id,
          productDBId: paymentItem.productDBId,
          productId: paymentItem.productId,
          count: paymentItem.count,
        };
        const res = await paymentAPI.signPayment(data);
        if (res.status === 200) {
          facebookPixelViewMessage();
          return res;
        } else {
          setModalMessage('잘못된 접근입니다.');
        }
      }
    } catch (err: any) {
      if (err.response.data.error == 'REJECT_CARD_PAYMENT') {
        setModalMessage(err.response.data.message);
      } else if (err.response.data.error == 'ALREADY_PROCESSED_PAYMENT') {
        setModalMessage('이미 처리 된 결제입니다.');
      } else if (err.response.data.error == 'PAYMENT_ERROR') {
        setModalMessage(
          `결제 중 오류가 발생했습니다.\n 해당 현상이 지속될 경우\n고객센터에 문의 바랍니다.`
        );
        // alert(
        //   '결제 중 오류가 발생했습니다.\n해당 현상이 지속될 경우 고객센터에 문의 바랍니다.'
        // );
      } else {
        setModalMessage('잘못된 접근입니다.');
        // alert('잘못된 접근입니다.');
      }
      window.location.href = `${window.location.origin}`;
    }
  };

  const getOrder = async (oid: string) => {
    setIsLoading(true);
    const headers = {
      oid: oid,
    };
    const res = await paymentAPI.getOrderByOid(headers);
    if (res.data.error === 'ORDER_NOT_FOUND') {
      console.log('ERROR : ORDER_NOT_FOUND');
      setIsOrderNotFound(true);
      setPaymentInfo(null);
      setIsLoading(false);
      return res;
    } else {
      console.log('data:', res.data);
      const paymentInf: PaymentInfo = {
        user_id: res.data.orderItems[0].user_id,
        priceOrigin: res.data.product.price,
        price: res.data.orderItems[0].total_price,
        discount: res.data.product.discount,
        productDBId: res.data.product.id,
        productId: res.data.product.productId,
        productName: res.data.product.title,
        customerName: res.data.orderItems[0].customerName,
        customerPhone: res.data.orderItems[0].customerPhone,
        selectedOption: res.data.orderItems[0].selectedOption
          ? res.data.orderItems[0].selectedOption
          : [],
        count: res.data.orderItems.length,
        spaceTitle: res.data.product.spaceTitle,
      };
      setPaymentInfo(paymentInf);
      return res;
    }
  };
  // INITIALIZE
  useEffect(() => {
    // TOSS PAYMENTS
    if (paymentInfoParams) {
      var decoded = decodeFromBase64(paymentInfoParams);
      const paymentInfoJSON = JSON.parse(decoded);

      if (paymentInfoJSON && paymentInfoJSON.price !== 0) {
        setPaymentInfo(paymentInfoJSON);
      }
    }
    // HECTO PAYMENTS
    else if (oidParams) {
      console.log('oidParams', oidParams);
      var oidDecoded = decodeFromBase64(oidParams);
      console.log('oidDecoded', oidDecoded);
      getOrder(oidDecoded);
    }
  }, []);

  // setState delay
  useEffect(() => {
    console.log('paymentInfo', paymentInfo);
    if (paymentInfo) {
      // var res = null;
      // res = paymentSigning(paymentInfo); TOSS
      setIsLoading(false);
    }
  }, [paymentInfo]);

  const goPurchaseList = (e: any, uid: string) => {
    e.preventDefault();
    window.location.href = `/purchase-list?uid=${uid}`;
  };
  const goMain = (e: any) => {
    e.preventDefault();
    window.location.href = `/`;
    // window.close();
  };

  return (
    <GlobalContainer>
      {showModal && (
        <AlertModal
          title="알림"
          isPadding={false}
          onCancel={() => setShowModal(false)}
        >
          {modalMessage}
        </AlertModal>
      )}
      <Container>
        {isLoading ? (
          <LoadingPage content="결제 정보를 불러오는중입니다.." />
        ) : (
          <ContentsContainer>
            <HeaderWrapper>
              <IconWrapper>
                <CloseButton
                  src={icon_close_black}
                  onClick={(e) => goMain(e)}
                />
              </IconWrapper>
            </HeaderWrapper>
            {paymentInfo && !isOrderNotFound && (
              <ContentsWrapper marginTop="20px" align="left">
                <>
                  <PageTitle>주문이 완료되었습니다.</PageTitle>
                  <TextSpaceTitle>{paymentInfo.spaceTitle}</TextSpaceTitle>
                  <TextProductTitle>{paymentInfo.productName}</TextProductTitle>
                  <OptionWrapper>
                    {/* map */}
                    {paymentInfo && (
                      <>
                        {paymentInfo.selectedOption !== undefined &&
                          paymentInfo.selectedOption !== null &&
                          (paymentInfo.selectedOption.length === 0 ? (
                            <OptionItemWrapper>
                              <OptionTitle>
                                {paymentInfo.productName}
                              </OptionTitle>
                              <OptionInfoWrapper>
                                <OptionCount>
                                  수량 {paymentInfo.count}개
                                </OptionCount>

                                <OptionPriceWrapper>
                                  <OptionPriceText>
                                    {printCleanDiscount(
                                      paymentInfo.discount.toString()
                                    )}
                                    %
                                  </OptionPriceText>
                                  {paymentInfo.price.toLocaleString()}원
                                </OptionPriceWrapper>
                              </OptionInfoWrapper>
                            </OptionItemWrapper>
                          ) : (
                            paymentInfo.selectedOption.map((option, index) => {
                              if (option.count !== 0) {
                                return (
                                  <OptionItemWrapper key={index}>
                                    <OptionTitle>{option.title}</OptionTitle>
                                    <OptionInfoWrapper>
                                      <OptionCount>
                                        수량 {option.count}개
                                      </OptionCount>
                                      <OptionPriceWrapper>
                                        <OptionPriceText>
                                          {printCleanDiscount(
                                            paymentInfo.discount.toString()
                                          )}
                                          %
                                        </OptionPriceText>
                                        {getOptionFinalPrice(
                                          paymentInfo.discount.toString(),
                                          paymentInfo.priceOrigin.toString(),
                                          option.price,
                                          option.count
                                        ).toLocaleString()}
                                        원
                                      </OptionPriceWrapper>
                                    </OptionInfoWrapper>
                                  </OptionItemWrapper>
                                );
                              }
                            })
                          ))}
                      </>
                    )}
                  </OptionWrapper>
                  <PriceWrapper>
                    결제금액
                    <PriceText>{addCommaToNum(paymentInfo.price)}원</PriceText>
                  </PriceWrapper>
                </>
              </ContentsWrapper>
            )}
            {isOrderNotFound && (
              <>
                <PageTitle>주문을 불러오는 중 오류가 발생하였습니다.</PageTitle>
                <TextSpaceTitle>
                  해당 현상이 지속될 경우 cs@favapp.io로 문의 부탁드립니다.
                </TextSpaceTitle>
              </>
            )}
            <ButtonContainer>
              <ButtonSolid60 btype="secondary" onClick={(e) => goMain(e)}>
                홈으로 이동
              </ButtonSolid60>
            </ButtonContainer>
          </ContentsContainer>
        )}
      </Container>
    </GlobalContainer>
  );
};

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const TextSpaceTitle = styled.div`
  color: ${palette.neutral006};
  ${BodySubmenu};

  margin-top: 2.1875rem;
`;
const TextProductTitle = styled.div`
  margin-top: 0.3125rem;
  color: ${palette.neutral007};

  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem; /* 155.556% */
`;
const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.9375rem;
  gap: 0.3125rem;

  max-height: 300px;
  overflow-y: auto;
`;
const OptionItemWrapper = styled.div`
  padding: 0.9375rem;
  border-radius: 10px;
  background-color: ${palette.neutral001};
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
`;
const OptionTitle = styled.div`
  text-align: left;
  padding: 0rem 4px;
  color: ${palette.neutral005};
  ${TextButton};
`;
const OptionInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0rem 4px;
`;
const OptionCount = styled.div`
  ${palette.neutral006};
  ${BodySubmenu};
`;

const OptionPriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  color: ${palette.neutral006};
  gap: 3px;

  ${BodySubmenu};
`;
const OptionPriceText = styled.div`
  color: ${palette.primary};

  font-size: 1rem;

  font-weight: 500;
  line-height: normal;
`;
const PriceWrapper = styled.div`
  margin-top: 1.25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${palette.neutral006};
  ${SubTitle};

  padding: 0rem 4px;
`;
const PriceText = styled.div`
  color: #393939;
  text-align: right;
  ${HPageTitle20};
`;
export default PaymentSuccess;
