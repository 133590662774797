import {
  loadPaymentWidget,
  PaymentWidgetInstance,
} from '@tosspayments/payment-widget-sdk';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { nanoid } from 'nanoid';
import GlobalContainer from '../GlobalContainer';
import { palette } from 'modules/defines/styles';
import { useLocation } from 'react-router';
import { decodeFromBase64, encodeToBase64 } from 'modules/defines/encrypt';
import { PaymentInfo, Product } from 'modules/defines/interfaces';
import { ButtonSolid60 } from 'components/common/Button';
import { makeRandomID } from 'utils/util';
import { AlertModal } from 'components/common/Modal';
import StateContainer from 'containers/StateContatiner';

const PurchaseWidget = () => {
  const paymentWidgetRef = useRef<PaymentWidgetInstance | null>(null);
  const paymentMethodsWidgetRef = useRef<ReturnType<
    PaymentWidgetInstance['renderPaymentMethods']
  > | null>(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paymentInfoParams = queryParams.get('p') || '';
  const selector: string = '#payment-widget';

  // payment Info
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfo | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const onClickFunc = useRef<Function | null>(null);
  // initialize Product
  const loadPayment = async (price: number, customerKey: string) => {
    const clientKey: any = process.env.REACT_APP_TOSS_CLIENT_KEY;
    const paymentWidget = await loadPaymentWidget(clientKey, customerKey);
    const paymentMethodsWidget = paymentWidget.renderPaymentMethods(selector, {
      value: price,
    });
    paymentWidget.renderAgreement('#agreement');
    paymentWidgetRef.current = paymentWidget;
    paymentMethodsWidgetRef.current = paymentMethodsWidget;
  };

  useEffect(() => {
    try {
      if (paymentInfoParams) {
        var decoded = decodeFromBase64(paymentInfoParams);
        const paymentInfoJSON = decoded ? JSON.parse(decoded) : '';
        if (paymentInfoJSON && paymentInfoJSON.price !== 0) {
          setPaymentInfo(paymentInfoJSON);
          loadPayment(
            paymentInfoJSON.price,
            `${paymentInfoJSON.user_id}_${makeRandomID(8)}`
          );
        } else {
          setModalMessage('결제 정보가 없습니다. 다시 시도해주세요.');
          setModalOpen(true);
          onClickFunc.current = () =>
            (window.location.href = `${window.location.origin}`);
          // alert('결제 정보가 없습니다. 다시 시도해주세요.');
          // window.location.href = `${window.location.origin}`;
        }
      } else {
        setModalMessage('결제 정보가 없습니다. 다시 시도해주세요.');
        setModalOpen(true);
        onClickFunc.current = () =>
          (window.location.href = `${window.location.origin}`);
        // alert('결제 정보가 없습니다. 다시 시도해주세요.');
        // window.location.href = `${window.location.origin}`;
      }
    } catch (error) {
      // alert(error);
      setError(true);
    }
  }, []);

  const onClickPayButton = async (e: any) => {
    e.preventDefault();
    const paymentWidget = paymentWidgetRef.current;
    try {
      if (paymentWidget) {
        const paymentInfoEnc = encodeToBase64(JSON.stringify(paymentInfo));
        await paymentWidget.requestPayment({
          orderId: nanoid(),
          orderName: paymentInfo?.productName || '',
          customerName: paymentInfo?.customerName,
          successUrl: `${window.location.origin}/payment/complete?p=${paymentInfoEnc}`,
          failUrl: `${window.location.origin}/payment/fail?p=${paymentInfoEnc}`,
        });
      } else {
        setModalMessage('결제 정보가 없습니다. 다시 시도해주세요.');
        setModalOpen(true);
        onClickFunc.current = () =>
          (window.location.href = `${window.location.origin}`);
      }
    } catch (err) {
      let error = err as Error;
      setModalOpen(true);
      setModalMessage(error.message);
      onClickFunc.current = null;
    }
  };

  return (
    <>
      {!error ? (
        <GlobalContainer>
          {modalOpen && (
            <AlertModal
              isPadding={false}
              title="알림"
              onCancel={() => {
                setModalOpen(false);
                onClickFunc.current && onClickFunc.current();
              }}
            >
              {modalMessage}
            </AlertModal>
          )}
          <Container>
            <div id="payment-widget" />
            <div id="agreement" />
            <ButtonContainer>
              <ButtonSolid60
                btype="primary"
                onClick={(e) => onClickPayButton(e)}
              >
                결제하기
              </ButtonSolid60>
            </ButtonContainer>
          </Container>
        </GlobalContainer>
      ) : (
        <StateContainer type="fail" />
      )}
    </>
  );
};

const Container = styled.div`
  height: 100vh;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 2.5rem;
  justify-content: center;
  padding: 0 1.25rem;
`;

export default PurchaseWidget;
