import axios from 'axios';
import { apiPaths } from 'modules/defines/paths';

const apiBilling = axios.create({
  baseURL: 'https://api.tosspayments.com/v1/',
  xsrfHeaderName: 'X-CSRFToken',
  withCredentials: true,
  headers: {
    Authorization: btoa(process.env.REACT_APP_TOSS_SECRET_KEY_BILLING + ':'),
    'Content-Type': 'application/json',
  },
});

const apiBROZ = axios.create({
  baseURL: process.env.REACT_APP_LOCAL
    ? apiPaths.devBaseUrl
    : apiPaths.awsBaseUrl,
  xsrfHeaderName: 'X-CSRFToken',
  withCredentials: true,
});

export const billingAPI = {
  paymentSign: (data: object) =>
    apiBilling.post('/payments/confirm/', { data }),
  getBillingKey: (data: object) =>
    apiBilling.post('/billing/authorizations/issue/', data),
};

export const productAPI = {
  getListMain: (headers: object) => apiBROZ.get('/product/main/', { headers }),
  getProductSpace: (sid: string) =>
    apiBROZ.get('/product/space-product/' + sid),
  getListAll: () => apiBROZ.get('/product/list/'),
  getListAllTEST: () => apiBROZ.get('/product/list-test/'),
  getOne: (id: string) => apiBROZ.get('/product/' + id),
  getShareLink: (data: object) =>
    apiBROZ.post('/product/get-share-link/', data),
};

export const paymentAPI = {
  signPayment: (data: object) => apiBROZ.post('/payment/sign/', data),
  requestRefund: (data: object) =>
    apiBROZ.post('/payment/request-refund/', data),
  getOrderByOid: (headers: object) =>
    apiBROZ.get('/payment/get-order-info/', { headers }),
};

export const userAPI = {
  purchaseInfo: (oid: string, headers: object) =>
    apiBROZ.get(`/user/purchase-detail2/${oid}`, { headers }), // FIXME
  purchaseInfoAnonymous: (oid: string, headers: object) =>
    apiBROZ.get(`/user/purchase-detail-anonymous/${oid}`, { headers }),
  purchaseList: (headers: object) =>
    apiBROZ.get('/user/purchase-list/', { headers }),
  getTestUserUid: () => apiBROZ.get('/store/user/get-test-user-uid'),
};
