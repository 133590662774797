import styled, { keyframes } from 'styled-components';
import GlobalContainer from './GlobalContainer';
import {
  Badge,
  BodySubmenu,
  Button,
  Caption,
  HPageTitle20,
  SubTitle,
  TabTitle,
  TextButton,
  TitleAll,
  palette,
} from 'modules/defines/styles';
import { useParams, useSearchParams } from 'react-router-dom';
import { paymentAPI, userAPI } from 'api';
import { useEffect, useState } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { spinner } from 'assets/lottie';
import {
  ClusteredOption,
  PurchaseItem,
  PurchaseItemBundle,
} from 'modules/defines/interfaces';
import BottomSheet from 'components/common/BottomSheet';
import RefundInfo from 'components/ProductDetail/RefundInfo';
import { formatExpireDateStr } from 'utils/util';
import { AlertModal } from 'components/common/Modal';
import ProductInfo from 'components/ProductDetail/ProductInfo';
import Deadline from 'components/common/Deadline';
import changeLocaleDateString from 'utils/changeLocaleDateString';
import Header from 'components/common/Header';
import { UserAgent } from 'utils/userAgent';
import { decodeFromBase64 } from 'modules/defines/encrypt';
import AppBanner from 'components/common/AppBanner';
import { SafeAreaInAppAndroid } from 'components/common/Common';
const PurchaseDetailContainer = () => {
  const [searchParams] = useSearchParams();
  const { os, browser } = UserAgent;
  const { oid: orderIdNumber } = useParams();
  const [value, setValue] = useState('');
  const [show, setShow] = useState(false);
  const [modal, setModal] = useState<boolean>(false);
  const uidFromApp = searchParams.get('uid'); // 앱에서는 해당 uid로 작동하도록, 웹에서는 null
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [exceptionMessage, setExceptionMessage] = useState<string>('');
  const [purchaseItem, setPurchaseItem] = useState<PurchaseItemBundle>(); // 여러개 옵션 중 하나만 불러와서 대표되는 정보들을 표기
  const [purchaseItems, setPurchaseItems] = useState<PurchaseItem[]>([]);
  const [clusteredOption, setClusteredOption] = useState<ClusteredOption[]>([]);
  const [refundLoading, setRefundLoading] = useState<boolean>(false);
  const date = purchaseItem && new Date(purchaseItem.paidAt);
  const year = date && date.getFullYear();
  const month = date && String(date.getMonth() + 1).padStart(2, '0');
  const day = date && String(date.getDate()).padStart(2, '0');
  const weekday =
    date && ['일', '월', '화', '수', '목', '금', '토'][date.getDay()];
  const time =
    date &&
    date.toLocaleTimeString('ko-KR', {
      hour: '2-digit',
      minute: '2-digit',
      hourCycle: 'h24',
    });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const formattedDate = `${year}.${month}.${day} (${weekday}) ${time}`;

  const clusterOption = async (items: PurchaseItem[]) => {
    let clusteredOpt: ClusteredOption[] = [];
    await Promise.all(
      items.map((item) => {
        if (item.option) {
          const found = clusteredOpt.find(
            (cluster) => cluster.title === item.option.title
          );
          if (found) {
            found.count += 1;
          } else {
            clusteredOpt.push({
              title: item.option.title,
              price: item.option.price,
              count: 1,
            });
          }
        }
      })
    );
    return clusteredOpt;
  };

  // DATA INITIALIZE
  useEffect(() => {
    if (uidFromApp) {
      const headers = {
        id: decodeFromBase64(uidFromApp),
      };
      if (orderIdNumber) {
        userAPI.purchaseInfo(orderIdNumber, headers).then((res) => {
          if (res.data.message === 'OK') {
            setPurchaseItem(res.data.representItem);
            setPurchaseItems(res.data.userPurchaseItems);
            clusterOption(res.data.userPurchaseItems).then((result) => {
              setClusteredOption(result);
            });
          } else if (res.data.message === 'USER_NOT_FOUND') {
            setExceptionMessage('유저 정보가 확인되지 않습니다');
          }
        });
      } else {
        setExceptionMessage('잘못된 접근입니다.');
        setIsLoading(false);
      }
    } else {
      setExceptionMessage('가 확인되지 않습니다.');
      setIsLoading(false);
    }
  }, []);

  // ( LOADING ) setState Delay
  useEffect(() => {
    if (purchaseItem && purchaseItems && clusteredOption) {
      console.log('--------------------------------------');
      console.log(purchaseItem, 'purchaseItem');
      console.log(purchaseItems, 'purchaseItems');
      console.log(clusteredOption, 'clusteredOption');
      setIsLoading(false);
    }
  }, [purchaseItem, purchaseItems, clusteredOption]);

  // 환불 요청 버튼 클릭 시
  const requestRefundHandler = async (
    orderIdNumber: string,
    reason: string
  ) => {
    if (reason === '') {
      setIsModalOpen(true);
      setModalMessage('상품을 환불하는 이유를 입력해주세요.');
    } else {
      setRefundLoading(true);
      await paymentAPI
        .requestRefund({ orderIdNumber: orderIdNumber, reason: reason })
        .then((res) => {
          if (res.data.success === true) {
            setIsModalOpen(true);
            setModalMessage('환불 신청이 완료되었습니다.');
          } else if (
            res.data.success === false &&
            res.data.message === 'ALREADY_REQUESTED'
          ) {
            setIsModalOpen(true);
            setModalMessage(
              '이미 환불 신청이 완료되었습니다. 잠시만 기다려주시기 바랍니다.'
            );
          } else {
            setIsModalOpen(true);
            setModalMessage(
              '환불 신청에 실패하였습니다. 해당 현상이 지속되면 cs@favapp.io 로 문의 바랍니다.'
            );
          }
        })
        .finally(() => {
          setRefundLoading(false);
          setShow(false);
        });
    }
  };
  const productUseHandler = (
    e: any,
    orderIdNumber: string,
    expireAt: string,
    orderId: string
  ) => {
    e.stopPropagation();

    window.location.href = `/purchase-ticket/${orderIdNumber}?uid=${uidFromApp}`;
  };

  const isOptionTotallyUsed = (purchaseItemArray: PurchaseItem[]) => {
    const optionTotallyUsed: boolean | undefined =
      purchaseItemArray &&
      purchaseItemArray.find((item) => item.usedAt === null) === undefined;
    return optionTotallyUsed;
  };

  return (
    <GlobalContainer padding={false}>
      {modal && (
        <AlertModal
          title="알림"
          isPadding={false}
          onCancel={() => setModal(false)}
        >
          {modalMessage}
        </AlertModal>
      )}

      {isModalOpen && (
        <AlertModal
          isPadding={false}
          onCancel={() => {
            setIsModalOpen(false);
          }}
          title={'알림'}
        >
          {modalMessage}
        </AlertModal>
      )}
      {os.isAndroid && browser.isFavApp && <SafeAreaInAppAndroid />}
      <AppBanner os={os} browser={browser} isFixed={false} />
      <Header isFavApp={browser.isFavApp} title={''} />
      <SectionContainer>
        {isLoading ? (
          <LoadingContainer>
            <Player
              style={{
                width: '48px',
                height: '48px',
              }}
              id="spinner_player"
              src={spinner}
              autoplay
              loop
            />
          </LoadingContainer>
        ) : (
          <>
            {/* 상품 정보 */}

            <HeaderContainer>
              <OrderTitleWrapper>
                <OderDate>
                  {purchaseItem?.paidAt.split('T')[0].replace(/-/g, '.')}
                </OderDate>
                <OderNumber>
                  주문 번호&nbsp;
                  {purchaseItem?.orderIdNumber}
                </OderNumber>
                {/* <OderCheckButton type="button">사용확인</OderCheckButton> */}
              </OrderTitleWrapper>

              <Layout>
                <OderSpaceTitle>{purchaseItem?.spaceTitle}</OderSpaceTitle>
                <OderTitle>{purchaseItem?.orderName}</OderTitle>
                <OderWrapper>
                  {/* {purchaseItem?.productImg ? (
                    <OderImage
                      loading="lazy"
                      src={convertToWebp(purchaseItem?.productImg ?? '')}
                      alt="product"
                    />
                  ) : (
                    <ImgEmpty>
                      이미지가
                      <br />
                      없습니다.
                    </ImgEmpty>
                  )} */}

                  <OderProductInfoWrapper>
                    <OderProductInfo className="expire">
                      <OderProductItem className="deadline">
                        유효기간 :{formatExpireDateStr(purchaseItem?.expireAt)}
                      </OderProductItem>
                    </OderProductInfo>
                    {clusteredOption.map((option, index) => {
                      return (
                        <OderProductInfo
                          className="product"
                          key={`option_${option.title}_${index}`}
                        >
                          <OderProductItem>{option.title}</OderProductItem>
                          <OderProductItem className="count">
                            수량&nbsp;{option.count}개
                          </OderProductItem>
                        </OderProductInfo>
                      );
                    })}
                  </OderProductInfoWrapper>
                </OderWrapper>
                <OderProductInfo className="total">
                  <TotalProduct className="product">
                    수량&nbsp;
                    {purchaseItems && purchaseItems.length}개
                  </TotalProduct>
                  <TotalProduct className="price">
                    {purchaseItem?.total_price.toLocaleString()}원
                  </TotalProduct>
                </OderProductInfo>
              </Layout>
            </HeaderContainer>
            {/* 사용처 안내 */}
            <ContentLayout>
              {purchaseItem?.spaceTitle && (
                <>
                  <Title bottomSpacing={0.94}>사용 안내</Title>
                  <InformationForUserWrapper>
                    <ProductInfo
                      spaceTitle={purchaseItem.spaceTitle}
                      spaceAddress={purchaseItem.spaceAddress || ''}
                    />
                    <Deadline
                      expireAt={changeLocaleDateString(purchaseItem.expireAt)}
                      category={purchaseItem?.productCategory}
                    />
                    <ConfirmButton
                      marginTop={1.25}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (
                          isOptionTotallyUsed(purchaseItems) ||
                          purchaseItem?.refundAt !== null ||
                          (purchaseItem?.expireAt &&
                            purchaseItem?.expireAt >
                              new Date().toISOString() ===
                              false)
                        ) {
                          setModal(true);
                          if (isOptionTotallyUsed(purchaseItems)) {
                            setModalMessage('이미 사용 완료된 상품입니다.');
                          }
                        } else {
                          productUseHandler(
                            e,
                            purchaseItem.orderIdNumber,
                            purchaseItem.expireAt,
                            purchaseItem.orderId
                          );
                        }
                      }}
                      disabled={
                        isOptionTotallyUsed(purchaseItems) ||
                        purchaseItem?.refundAt !== null ||
                        (purchaseItem.expireAt !== null &&
                          purchaseItem.expireAt > new Date().toISOString() ===
                            false)
                      }
                    >
                      {isOptionTotallyUsed(purchaseItems)
                        ? '사용 완료'
                        : purchaseItem?.refundAt !== null
                          ? '환불 완료'
                          : purchaseItem.expireAt !== null &&
                              purchaseItem.expireAt >
                                new Date().toISOString() ===
                                false
                            ? '기간 만료'
                            : '사용하기'}
                    </ConfirmButton>
                  </InformationForUserWrapper>
                </>
              )}
            </ContentLayout>
            {/* 환불 안내 */}
            <ContentLayout>
              <Title bottomSpacing={1.56}>환불 안내</Title>
              <RefundInfo
                noTitle
                fontStyle="Badge"
                category={purchaseItem?.productCategory || '푸드'}
              />
              <OutlineButton onClick={() => setShow(true)} type="button">
                환불
              </OutlineButton>
            </ContentLayout>
            {/* 구매자 정보 */}
            <ContentLayout>
              <Title bottomSpacing={0.94}>구매자 정보</Title>
              <CustomerInfoWrapper>
                <CustomerInfo>
                  <CustomerInfoItem className="title">이름</CustomerInfoItem>
                  <CustomerInfoItem className="desc">
                    {purchaseItem?.customerName}
                  </CustomerInfoItem>
                </CustomerInfo>
                <CustomerInfo>
                  <CustomerInfoItem className="title">
                    휴대폰 번호
                  </CustomerInfoItem>
                  <CustomerInfoItem className="desc">
                    {purchaseItem?.customerPhone}
                  </CustomerInfoItem>
                </CustomerInfo>
              </CustomerInfoWrapper>
            </ContentLayout>
            {/* 결제 정보 */}
            <ContentLayout>
              <Title bottomSpacing={0.94}>결제 정보</Title>

              <PaymentInfoWrapper>
                <CustomerInfo>
                  <CustomerInfoItem className="title">
                    결제 금액
                  </CustomerInfoItem>
                  <CustomerInfoItem className="desc">
                    {purchaseItem?.total_price.toLocaleString()}원
                  </CustomerInfoItem>
                </CustomerInfo>
                <CustomerInfo>
                  <CustomerInfoItem className="title">
                    결제 수단
                  </CustomerInfoItem>
                  <CustomerInfoItem className="desc">신용카드</CustomerInfoItem>
                </CustomerInfo>
                <CustomerInfo>
                  <CustomerInfoItem className="title">
                    거래 일시
                  </CustomerInfoItem>
                  <CustomerInfoItem className="desc">
                    {formattedDate}
                  </CustomerInfoItem>
                </CustomerInfo>
              </PaymentInfoWrapper>
              <OutlineButton
                onClick={() =>
                  purchaseItem?.receipt
                    ? window.open(purchaseItem?.receipt, '_blank')
                    : alert(
                        '영수증을 확인할 수 없습니다.\ncs@favapp.io로 문의바랍니다.'
                      )
                }
                type="button"
              >
                영수증 조회
              </OutlineButton>
              <NoticeWrapper>
                <NoticeComment>
                  결제 및 계좌 안내 시 상호명은 <Em>주식회사 브로즈</Em>로
                  표기됩니다.
                </NoticeComment>
              </NoticeWrapper>
            </ContentLayout>
            {/* 사용 정보 */}
            <ContentLayout key={purchaseItems.length}>
              <Title bottomSpacing={0.94}>사용 정보</Title>
              {purchaseItems.map((orderItem, idx) => {
                return (
                  <>
                    {orderItem.option !== null ? (
                      <PaymentInfoWrapper key={orderItem.id}>
                        <CustomerInfo>
                          <CustomerInfoItem className="title">
                            {orderItem.option.title} {'('}{' '}
                            {orderItem.option.price >= 0
                              ? `+${orderItem.option.price.toLocaleString()}원`
                              : `${orderItem.option.price.toLocaleString()}원`}{' '}
                            {')'}
                          </CustomerInfoItem>
                          <CustomerInfoItem className="desc">
                            {orderItem.usedAt
                              ? `사용 : ${orderItem.usedAt.split('+')[0].replace('T', ' ')}`
                              : '미사용'}
                          </CustomerInfoItem>
                        </CustomerInfo>
                        <OderNumber>
                          주문번호 : {orderItem.orderNumber}
                        </OderNumber>
                        <BorderBottom />
                      </PaymentInfoWrapper>
                    ) : (
                      <PaymentInfoWrapper key={orderItem.id}>
                        <CustomerInfo>
                          <CustomerInfoItem className="title">
                            {idx}
                            {') '}
                            {orderItem.orderName}
                          </CustomerInfoItem>
                          <CustomerInfoItem className="desc">
                            {orderItem.usedAt
                              ? `사용 : ${orderItem.usedAt.split('+')[0].replace('T', ' ')}`
                              : '미사용'}
                          </CustomerInfoItem>
                        </CustomerInfo>
                        <OderNumber>
                          주문번호 : {orderItem.orderNumber}
                        </OderNumber>
                        <BorderBottom />
                      </PaymentInfoWrapper>
                    )}
                  </>
                );
              })}
            </ContentLayout>
          </>
        )}
      </SectionContainer>
      {/* 환불 관련 BottomSheet */}
      {show ? (
        <BottomSheet
          hide={setShow}
          disabled={false}
          buttonType={'button'}
          onClick={function (): void {
            requestRefundHandler(purchaseItem?.orderIdNumber || '', value);
          }}
          ButtonContent={'환불 요청'}
          isLoading={refundLoading}
        >
          <RefundTitle>환불 요청</RefundTitle>

          <RefundInfoInputWrapper>
            <RefundInfoTitle>환불 이유</RefundInfoTitle>
            <RefundInput
              value={value}
              onChange={handleChange}
              placeholder="상품을 환불하는 이유를 입력해주세요."
            />
          </RefundInfoInputWrapper>
          <RefundInfoDesc>
            환불은 요청 후 영업일 기준 3~7일 내 처리됩니다.
          </RefundInfoDesc>
        </BottomSheet>
      ) : null}
    </GlobalContainer>
  );
};

const loading = keyframes`
  0% {
    background-color: rgba(0, 0, 0, 0.04);
  }
  50% {
    background-color: rgba(0, 0, 0, 0.08);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const ConfirmButton = styled.button<{ marginTop?: number }>`
  ${Button};
  width: 100%;
  color: white;
  background: ${palette.primary};
  padding-top: 0.87rem;
  padding-bottom: 0.88rem;

  border-radius: 0.625rem;
  text-decoration: none;
  cursor: pointer;
  margin-top: ${(props) => props.marginTop || 0}rem;
  &:disabled {
    background: ${palette.neutral001};
    color: rgba(193, 192, 201, 1);
    cursor: default;
  }
`;

const InformationForUserWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Layout = styled.div`
  margin-top: 28px;
`;

const RefundInput = styled.input`
  border-radius: 10px;
  border: 1px solid ${palette.neutral002};
  background: white;
  padding: 1.25rem;
  height: fit-content;
  ${TitleAll};
  &::placeholder {
    color: ${palette.neutral004};
    ${TitleAll};
  }
  &:focus {
    outline: none;
    border: 1px solid ${palette.primary};
  }
`;

const RefundInfoInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  width: calc(100% - 4.375rem);
  margin: 0rem auto 30px auto;
`;

const RefundInfoTitle = styled.div`
  ${Badge};
  color: ${palette.neutral006};
`;

const RefundInfoDesc = styled.div`
  font-size: 0.8125rem;
  color: ${palette.neutral005};
  font-weight: 400;
  line-height: 1.25rem;
  margin-bottom: 1.875rem;
  margin-left: 2.5rem;
`;

const HR = styled.hr`
  width: 100%;
  border: none;
  border-top: 1px solid ${palette.neutral003};
  margin: 15px 0;
`;

const RefundTitle = styled.h3`
  ${TabTitle};
  color: ${palette.neutral007};
  width: calc(100% - 3.125rem);
  margin: 1.875rem auto;
`;

const OrderTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 0.375rem;
  position: relative;
  align-items: center;
  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    bottom: -0.5rem;
    height: 1px;
    background: linear-gradient(0deg, #717171 0%, #717171 100%), #d9d9d9;
  }
`;

const OderSpaceTitle = styled.div`
  ${SubTitle};
  color: ${palette.neutral007};
`;

const OderTitle = styled.div`
  margin-top: 4px;
  ${TextButton};
  color: ${palette.neutral007};
  max-width: 23.625rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const OderDate = styled.div`
  ${SubTitle};
  color: ${palette.neutral007};
`;

const OderNumber = styled.div`
  ${Badge};
  color: ${palette.neutral005};
  font-family: 500;
`;

const OderProductInfo = styled.div`
  display: flex;
  justify-content: space-between;

  &.product {
    background-color: ${palette.neutral001};
    border-radius: 0.625rem;
    padding: 0.9375rem;
  }
  &.expire {
    height: 100%;
  }

  &.total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-top: calc(1rem + 0.94rem);
    margin-bottom: 3.125rem;
    &::before {
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      bottom: calc(1.25rem + 0.94rem);
      height: 1px;
      background-color: ${palette.neutral003};
    }
  }
`;

const TotalProduct = styled.li`
  list-style-type: none;
  &.product {
    ${SubTitle};
    color: ${palette.neutral007};
  }

  &.price {
    ${HPageTitle20};
    color: ${palette.neutral007};
  }
`;

const OderProductInfoWrapper = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const OderProductItem = styled.div`
  ${TextButton};
  color: ${palette.neutral005};

  &.count {
    color: ${palette.neutral006};
  }
  &.deadline {
    ${Badge};
    font-weight: 500;
    margin-top: -0.12rem;
    color: ${palette.accent};
  }
`;
const OderCheckButton = styled.button`
  color: white;
  width: fit-content;
  padding: 0.5rem;
  border-radius: 4px;
  background: ${palette.primary};
  ${Button};
`;

const OderImage = styled.img`
  border-radius: 4px;
  object-fit: cover;
  max-width: 7.5rem;
  max-height: 7.5rem;
  aspect-ratio: 120 / 120;
`;

const OderWrapper = styled.div`
  display: flex;
  gap: 0.625rem;
  width: 100%;
  margin: 0.5rem 0px;
`;
const NoticeWrapper = styled.div`
  padding-top: 0.625rem;
  padding-bottom: 1.4063rem;
`;

const NoticeComment = styled.div`
  ${Caption};
  color: ${palette.neutral005};
  text-align: center;
`;

const Em = styled.em`
  ${Caption};
  color: ${palette.primary};
  text-decoration: underline;
  text-underline-position: below;
  text-underline-offset: 0.1875rem;
`;

const ContentLayout = styled.section`
  margin-bottom: 3.125rem;

  & > button {
    margin-top: 1.38rem;
  }
`;

const OutlineButton = styled.button`
  background-color: white;
  ${Button};
  color: ${palette.neutral007};
  width: 100%;
  padding: 0.875rem 10px;
  border-radius: 5px;
  border: 1px solid ${palette.neutral004};
`;
const FlexLayout = styled.div`
  display: flex;
`;

const CustomerInfoWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 1.25rem;
`;

const PaymentInfoWrapper = styled(CustomerInfoWrapper)`
  padding: 0rem 20px 25px 20px;
`;

const CustomerInfoItem = styled.div`
  &.title {
    color: ${palette.neutral005};
    ${BodySubmenu};
  }

  &.desc {
    color: ${palette.neutral007};
    ${BodySubmenu};
  }
`;

const CustomerInfo = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h2<{ bottomSpacing?: number }>`
  ${HPageTitle20};
  color: ${palette.neutral007};
  position: relative;
  /* padding: 0 0.3125rem; */
  margin-bottom: ${(props) => props.bottomSpacing || 0}rem;
  &::after {
    content: '';
    display: block;
    width: 100%;
    padding: 0;
    margin-top: 0.9375rem;
    height: 1px;
    background-color: ${palette.neutral005};
  }
`;
const SectionContainer = styled.div`
  margin-top: 1.5625rem;
  display: flex;
  flex-direction: column;
  padding: 0rem 25px;
  // height: 100vh;
`;

const HeaderContainer = styled.div`
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline; */
  width: 100%;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const ImgEmpty = styled.div`
  border-radius: 4px;
  background-color: ${palette.neutral002};
  color: ${palette.neutral004};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  width: 120px;
  height: 120px;
  line-height: 1.2;
`;

/////////// FIXME ////////////////////// DESIGN 이후 다시 정리 필요
const BorderBottom = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${palette.neutral003};
`;

export default PurchaseDetailContainer;
